<template>
  <section class="page page-auth">
    <div class="container">
      <template v-if="initialPage === true">
        <div class="row">
          <div class="col-md-12">
            <div class="block-auth">
              <h2>Восстановление пароля</h2>
              <form class="page-title" @submit.prevent="sendEmail">
                <div class="form-group">
                  <input
                    v-model.trim="v$.email.$model"
                    class="form-control"
                    name="forgotPassword"
                    placeholder="Email"
                    type="text"
                  />
                  <small v-if="v$.email.$dirty && v$.email.email.$invalid">
                    Поле должно быть заполнено валидным email адресом
                  </small>
                </div>
                <div class="bottom">
                  <button class="button">Сбросить пароль</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </template>
      <template v-if="checkSecretKey === true">
        <div class="row">
          <div class="col-md-12">
            <div class="block-auth">
              <h2>Восстановление пароля</h2>
              <form class="page-title" @submit.prevent="sendSecretKey">
                <div class="form-group">
                  <input
                    v-model="v$.secretKey.$model"
                    class="form-control"
                    name="sendSecretKey"
                    placeholder="secretKey"
                    type="text"
                  />
                  <small v-if="v$.secretKey.$invalid">
                    Поле должно быть заполнено
                  </small>
                </div>
                <div class="bottom">
                  <button class="button">Сбросить пароль</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </template>

      <template v-if="updatePassword === true">
        <div class="row">
          <div class="col-md-12">
            <div class="block-auth">
              <h2>Сброс пароля</h2>
              <form class="page-title" @submit.prevent="sendNewPassword">
                <div class="main">
                  <div class="item">
                    <label>Новый пароль</label>
                    <div class="form-group">
                      <input
                        v-model="v$.newPassword.$model"
                        name="updatePassword"
                        class="form-control"
                        placeholder="Новый пароль"
                        type="password"
                      />
                      <small
                        v-if="
                          v$.newPassword.$dirty &&
                            v$.newPassword.minLength.$invalid
                        "
                      >
                        Длина пароля должна быть от
                        {{ v$.newPassword.minLength.$params.min }} символов
                      </small>
                      <small
                        v-if="
                          v$.newPassword.$dirty &&
                            v$.newPassword.maxLength.$invalid
                        "
                      >
                        Длина пароля должна быть не более
                        {{ v$.newPassword.maxLength.$params.max }} символов
                      </small>
                    </div>
                  </div>
                  <div class="item">
                    <label>Подтверждение</label>
                    <div class="form-group">
                      <input
                        v-model.trim="v$.passwordConfirm"
                        class="form-control"
                        name="passwordConfirm"
                        placeholder="Подтверждение"
                        type="password"
                      />
                    </div>
                  </div>
                </div>
                <div class="bottom">
                  <button class="button save">Сбросить</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { email, maxLength, minLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import NotificationService from "../services/notification.service";
import axios from "axios";

const passwordMask = "******";
export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data: () => ({
    initialPage: true,
    secretKey: "",
    checkSecretKey: false,
    updatePassword: false,
    email: "",
    newPassword: passwordMask
  }),
  validations: {
    email: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(32),
      email
    },
    secretKey: {
      required
    },
    newPassword: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(64)
    }
  },
  methods: {
    async sendEmail() {
      if (this.v$.email.$invalid) {
        this.v$.$touch();
        return;
      }

      let sendEmailData = {
        email: this.email
      };

      try {
        await axios
          .post(
            process.env.VUE_APP_BACKEND_ROOT_URL +
              "/notifications/email/forgot-password",
            sendEmailData
          )
          .catch(error => {
            if (error.response.status === 404) {
              NotificationService.error(
                "Что-то пошло не так. Возможно вы ввели не свой email."
              );
              throw error;
            }
          })
          .catch(error => {
            if (error.response.status === 500) {
              NotificationService.error(
                "Не удается сбросить пароль напишите в чат или в форму обратной связи на главной странице"
              );
              throw error;
            }
          })
          .then(res => {
            if (res.status === 200) {
              NotificationService.success(
                "Секретный ключ был отправлен вам на почту."
              );
              this.initialPage = false;
              this.checkSecretKey = true;
            }
          });
      } catch (e) {
        // nop
      }
    },
    async sendSecretKey() {
      if (this.v$.secretKey.$invalid) {
        this.v$.$touch();
        console.log(this.v$);
        return;
      }

      let secretKeyData = {
        email: this.email,
        secretKey: this.secretKey
      };

      try {
        await axios
          .post(
            process.env.VUE_APP_BACKEND_ROOT_URL +
              "/notifications/email/check-secretkey",
            secretKeyData
          )
          .catch(error => {
            if (error.response.status === 400) {
              console.log(error.response.statusCode);
              NotificationService.error(
                "Произошла ошибка пройдите процедуру сброса пароля еще раз"
              );
              throw error;
            }
          })
          .catch(error => {
            if (error.response.status === 500) {
              NotificationService.error(
                "Не удается сбросить пароль напишите в чат или в форму обратной связи на главной странице"
              );
              throw error;
            }
          })
          .then(res => {
            if (res.status === 200) {
              this.checkSecretKey = false;
              this.updatePassword = true;
            }
          });
      } catch (e) {
        // nop
      }
    },
    async sendNewPassword() {
      if (this.v$.newPassword.$invalid) {
        this.v$.$touch();
        return;
      }

      let updatePassword = {
        password: this.newPassword,
        secretKey: this.secretKey,
        email: this.email
      };

      try {
        await axios
          .post(
            process.env.VUE_APP_BACKEND_ROOT_URL +
              "/notifications/email/reset-password",
            updatePassword
          )
          .catch(error => {
            if (error.response.status === 400) {
              console.log(error.response.statusCode);
              NotificationService.error(
                "Произошла ошибка пройдите процедуру сброса пароля еще раз"
              );
              throw error;
            }
          })
          .catch(error => {
            if (error.response.status === 500) {
              NotificationService.error(
                "Не удается сбросить пароль напишите в чат или в форму обратной связи на главной странице"
              );
              throw error;
            }
          })
          .then(res => {
            if (res.status === 200) {
              NotificationService.success("Новый пароль успешно установлен!");
              this.$router.push("/login");
            }
          });
      } catch (e) {
        // nop
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "/public/template/scss/page-auth.scss";
</style>
